import { render, staticRenderFns } from "./CollapsibleFormGroup.vue?vue&type=template&id=4d29a63b&scoped=true"
import script from "./CollapsibleFormGroup.vue?vue&type=script&lang=js"
export * from "./CollapsibleFormGroup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d29a63b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DfbFormGroup: require('/dashboard/forms/dynamic-form-group/DfbFormGroup.vue').default,ChildCollapsibleFormGroup: require('/dashboard/website-editor/child-collapsible-form-group/ChildCollapsibleFormGroup.vue').default,DfbFormArray: require('/dashboard/forms/dynamic-form-array/DfbFormArray.vue').default})
