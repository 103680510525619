
import * as _ from "lodash"
import { mapGetters, mapActions } from "vuex"
import { ValidationObserver } from "vee-validate"
import FeatherIcon from "@/helpers/FeatherIcon"

import ComponentEditorMixin from "@/dashboard/mixins/component-editor-mixin"
import CollapsibleFormGroup from "@/dashboard/website-editor/collapsible-form-group"

/**
 * The sidebar component is perfect for vertical navigations. By default
 * the sidebar is fixed on the left of the window. We can customize the
 * positioning, branding, and color of the sidebar.
 *
 * The EditorSidebarHashbrown is separate from SidebarPotato and has its
 * own state provided through Vuex.
 */
export default {
  name: "EditorSidebarHashbrown",
  components: {
    ValidationObserver,
    CollapsibleFormGroup,
    FeatherIcon
  },
  mixins: [ComponentEditorMixin],
  props: {
    /**
     * Sidebar title.
     */
    title: {
      type: String,
      default: null
    },
    /**
     * The logo prop controls the branding logo at the top of the sidebar.
     *
     * This is typically the app logo.
     */
    logo: {
      type: String,
      default: null
    },
    /**
     * The alt text prop handles the alt text for the logo.
     */
    logoAlt: {
      type: String,
      default: null
    },
    /**
     * The hide logo prop controls whether to hide the logo. We allow this
     * in case we want to have a text title instead of a logo. When set to
     * `true` the title text will be visible.
     */
    hideLogo: {
      type: Boolean,
      default: true
    },
    /**
     * Base href prop determines the route the user will go to when
     * the sidebar logo or title are clicked.
     */
    baseHref: {
      type: String,
      default: "#"
    },
    /**
     * Sidebar position prop controls the positioning of the sidebar.
     *
     * Default is `fixed-left`. Values can be (undefined|fixed-left|fixed-right)
     */
    sidebarPosition: {
      type: String,
      default: "fixed-left",
      validator: (value) => {
        return ["", "fixed-left", "fixed-right"].includes(value)
      }
    },
    /**
     * The background color prop controls the background color
     * of the sidebar.
     *
     * Default is `white` and can be set to any or any gradient variety of the following:
     * (undefined|light|primary|secondary|warning|dark|info|danger|success)
     */
    backgroundColor: {
      type: String,
      default: "white"
    },
    /**
     * The sidebar type prop controls the text color of the sidebar. Use
     * light for light background colors or dark for dark background colors.
     *
     * Default is `light`
     */
    sidebarType: {
      type: String,
      default: "light",
      validator: (value) => {
        return ["", "light", "dark"].includes(value)
      }
    },
    /**
     * Sidebar breakpoint controls the responsive nature for collapsing menus
     * and the color scheme classes.
     */
    sidebarBreakpoint: {
      type: String,
      default: "xs"
    },
    /**
     * Toggle breakpoint controls when to hide the toggle button. Once hidden the toggle
     * event can be handled in an external component
     *
     * Default is 'lg'
     */
    toggleBreakpoint: {
      type: String,
      default: "lg"
    },
    /**
     * Whether sidebar should autoclose on mobile when clicking an item
     */
    autoClose: {
      type: Boolean,
      default: true
    },
    /**
     * Save Button Text
     */
    saveBtnText: {
      type: String,
      default: "Apply"
    },
    /**
     * Whether user can undo form changes
     */
    canReset: {
      type: Boolean,
      default: false
    },
    /**
     * Reset Button Text
     */
    resetBtnText: {
      type: String,
      default: "Undo"
    },
    /**
     * Display toast on apply changes
     */
    displaySaveToast: {
      type: Boolean,
      default: true
    },
    /**
     * Toast Title. Title of toast when aapplying changes
     */
    toastTitle: {
      type: String,
      default: "Applied Changes"
    },
    /**
     * Toast content. Message when applying form changes
     */
    toastContent: {
      type: String,
      default: "Successfully applied form changes and updated page settings."
    },
    /**
     * Toast options for editor sidebar
     */
    toastOptions: {
      type: Object,
      default() {
        return {
          noAutoHide: false,
          autoHideDelay: 5000,
          toaster: "b-toaster-bottom-right"
        }
      }
    },
    /**
     * Input sizes for form fields
     */
    inputSizes: {
      type: String,
      default: "sm"
    },
  },
  data() {
    return {
      form: {},
      localForm: null,
      cachedForm: null,
      localSettings: null,
      cachedSettings: null,
      isSaved: false,
      isFullWidth: true
    }
  },
  computed: {
    ...mapGetters({
      showEditorSidebar: "editorSidebar/getShowEditorSidebar",
      toggleEditorSidebar: "editorSidebar/getToggleEditorSidebar",
      formSettings: "editorSidebar/getFormSettings",
      websiteOptions: "website/getSelectedWebsite",
      selectedPage: "editorSidebar/getSelectedPage",
    }),
    /**
     * Check if form has changes
     */
    hasChanges() {
      const hasChange = !_.isEqual(this.localForm, this.cachedForm)
      return hasChange
    }
  },
  watch: {
    /**
     * Watch and rebuild form model when formSettings change.
     */
    formSettings: {
      handler(newVal, oldVal) {
        this.initFormSettings()
      },
      deep: true
    }
  },
  /**
   * Cache form values on created.
   */
  created() {
    this.initFormSettings()
  },
  mounted() {
    /**
     * We check the sidebar instance property. If the sidebar instance and
     * the showSidebar flag exists, we add global styles to the body tag.
     *
     * This is a fallback for an app that may not have an index.html or
     * App.vue file, where we would normally set the body classes.
     *
     * Instance Properties can be read about here:
     * - https://vuejs.org/v2/cookbook/adding-instance-properties.html
     */
    if (this.showEditorSidebar) {
      const docClasses = document.body.classList
      docClasses.add("g-sidenav-pinned")
      docClasses.add("g-sidenav-show")

      if (this.toggleEditorSidebar) {
        docClasses.add("g-sidenav-hidden")
        docClasses.remove("g-sidenav-pinned")
        docClasses.remove("g-sidenav-show")
      } else {
        docClasses.add("g-sidenav-pinned")
        docClasses.remove("g-sidenav-hidden")
      }
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    }
  },
  /**
   * Hide sidebar on destroy
   */
  beforeDestroy() {
    if (this.showEditorSidebar) {
      this.onShowEditorSidebar(false)
    }
  },
  methods: {
    ...mapActions({
      onToggleEditorSidebar: "editorSidebar/toggleEditorSidebar",
      onShowEditorSidebar: "editorSidebar/displayEditorSidebar",
      onMouseEnter: "editorSidebar/onMouseEnter",
      onMouseLeave: "editorSidebar/onMouseLeave",
      onApplyFormSettings: "editorSidebar/applyFormSettings",
      updateWebsiteOptions: "website/updateWebsiteOptions",
      updateGlobalStyles: "website/updateGlobalStyles"
    }),
    /**
     * Submit save form
     */
    async onSubmit(isValid) {
      // If editing a navigation element, submit global
      if (isValid) {
        if (this.isNavigation) {
          this.onSubmitGlobal()
        } else {
          // Parse form
          const parsedForm = JSON.parse(JSON.stringify(this.localForm))
          const fSettings = _.cloneDeep(this.localSettings)

          // Update settings with form values.
          const updatedSettings = this.updateFormSettings(parsedForm, fSettings)
          // Reduce form settings
          const newSettingsObj = this.reduceFormSettings(updatedSettings)
          // Then Update form settings Commit to Vuex
          await this.onApplyFormSettings({
            ...this.formSettings,
            ...newSettingsObj
          })
            .then((resp) => {
              if (this.displaySaveToast && resp && resp.success) {
                this.toastInfo(`${ this.toastTitle } - ${ this.toastContent }`)
              }

              if (this.displaySaveToast && resp && !resp.success) {
                this.$bvToast.toast("Error applying form changes.", {
                  title: "Apply Settings Error",
                  variant: "danger",
                  ...this.toastOptions
                })
              }
            })
            .catch((error) => {
              this.$rollbar.error('Hashbrown: Error applying form changes', error)
              this.$bvToast.toast("Error applying form changes. " + error, {
                title: "Apply Settings Error",
                variant: "danger",
                ...this.toastOptions
              })
            })

          this.isSaved = true
        }
      }
    },
    /**
     * Save website options update
     */
    async onSubmitGlobal() {
      this.isLoading = true

      // Update navbar settings
      await this.saveNavigationUpdate()

      // Rebuild website object
      const website = _.merge(_.cloneDeep(this.websiteOptions), JSON.parse(JSON.stringify(this.formSettings.website)))

      // Update website object with updated navbar/footer settings
      if (this.formSettings.isNavbar) {
        // If navbar
        website.nav_component = this.formSettings
      } else {
        // Else footer
        website.footer_component = this.formSettings
      }

      try {
        // Update website options
        await this.updateWebsiteOptions(website)
        await this.updateGlobalStyles(website.global_styles)

        this.isSaved = true
        let successMessage = "Styles successfully updated."
        if (this.isNavbar) {
          successMessage = "Navbar styles successfully updated."
        } else if (this.isFooter) {
          successMessage = "Footer styles successfully updated."
        }

        this.toastSuccess(successMessage)
      } catch (e) {
        this.toastError("Error updating navigation styles")
        this.$rollbar.error('Hashbrown: Error updating navigation styles', e)
      } finally {
        this.isLoading = false
      }
    },
    /**
     * Submit save form
     */
    saveNavigationUpdate() {
      try {
        // Parse form
        const parsedForm = JSON.parse(JSON.stringify(this.localForm))
        const fSettings = _.cloneDeep(this.localSettings)

        // Update settings with form values.
        const updatedSettings = this.updateFormSettings(parsedForm, fSettings)
        // Reduce form settings
        const newSettingsObj = this.reduceFormSettings(updatedSettings)

        // Merge updates into component
        // const clonedComponent = _.cloneDeep(this.nav_component)
        // const newComponent = Object.assign(clonedComponent, newSettingsObj)
        // Then Update form settings Commit to Vuex
        return this.onApplyFormSettings({
          ...this.formSettings,
          ...newSettingsObj
        })
      } catch (error) {
        // console.error("Save Navigation Update : ", error)
        this.$rollbar.error('Hashbrown: Error updating navigation', e)
      }
    },
    /**
     * Reset form to its original cached state.
     */
    resetForm() {
      // Reset form model and fields
      this.localForm = _.cloneDeep(this.cachedForm)

      // Reset form and cache on next Vue update
      requestAnimationFrame(() => {
        this.cachedForm = _.cloneDeep(this.localForm)
        if (this.$refs.formObs) {
          this.$refs.formObs.reset()
        }
      })
    },
    toggleSideBar() {
      this.isFullWidth = !this.isFullWidth
    }
  }
}
