
import { EventBus } from "@/utils/event-bus"

/**
 * Simple navbar to be used in the website-editor nuxt template
 *
 */
export default {
  name: "NavbarHalloumi",
  methods: {
    onBack(event) {
      /**
       * Event triggered when clicking the Back button.
       * In Nuxt this event should trigger the method: $router.go(-1)
       *
       * @type { object }
       */
      this.$emit("onBack", event)
    },
    onSave(event) {
      /**
       * Event triggered when clicking the 'Save Draft' button
       *
       * @type { object }
       */
      this.$emit("onSave", event)
    },
    onPublish(event) {
      /**
       * Event triggered when clicking the 'Save & Publish' button
       *
       * @type { object }
       */
      this.$emit("onPublish", event)
    },
  },
}
