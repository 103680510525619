import { render, staticRenderFns } from "./EditorSidebarHashbrown.vue?vue&type=template&id=00b76a88&lang=pug"
import script from "./EditorSidebarHashbrown.vue?vue&type=script&lang=js"
export * from "./EditorSidebarHashbrown.vue?vue&type=script&lang=js"
import style0 from "./EditorSidebarHashbrown.vue?vue&type=style&index=0&id=00b76a88&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FeatherIcon: require('/helpers/FeatherIcon.vue').default,CollapsibleFormGroup: require('/dashboard/website-editor/collapsible-form-group/CollapsibleFormGroup.vue').default})
