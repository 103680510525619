
import UIDMixin from '@/dashboard/mixins/uid-mixin'
import DfbFormGroup from '@/dashboard/forms/dynamic-form-group'
import DfbFormArray from '@/dashboard/forms/dynamic-form-array'

/**
 * CollapsibleFormGroup wraps a collapsible state around a Dynamic Form,
 * so that we can individually collapse form groups in the Editor Sidebar.
 */
export default {
  name: 'ChildCollapsibleFormGroup',
  components: {
    DfbFormGroup,
    DfbFormArray
  },
  mixins: [UIDMixin],
  props: {
    setting: {
      type: Object,
      default: () => { }
    },
    form: {
      type: [Object, Array],
      default: null
    },
    /**
     * Override default collapse state
     */
    displayCollapse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showCollapse: false
    }
  },
  created() {
    if (this.displayCollapse) {
      this.showCollapse = this.displayCollapse
    }
  },
  methods: {
    toggleCollapse() {
      this.showCollapse = !this.showCollapse
    },
    getLabel() {

    }
  }
}
