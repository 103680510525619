import { render, staticRenderFns } from "./ChildCollapsibleFormGroup.vue?vue&type=template&id=75d9e2b7&scoped=true"
import script from "./ChildCollapsibleFormGroup.vue?vue&type=script&lang=js"
export * from "./ChildCollapsibleFormGroup.vue?vue&type=script&lang=js"
import style0 from "./ChildCollapsibleFormGroup.vue?vue&type=style&index=0&id=75d9e2b7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75d9e2b7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DfbFormArray: require('/dashboard/forms/dynamic-form-array/DfbFormArray.vue').default,DfbFormGroup: require('/dashboard/forms/dynamic-form-group/DfbFormGroup.vue').default})
